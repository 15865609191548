import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    "& .MuiDialog-paperWidthSm": {
      backgroundColor: "#131212",
      borderRadius: "5px",
    },
  },
  title: {
    backgroundColor: "#131212",
  },
  email: {
    textDecoration: "none",
    color: "white",
  },
});

export default function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;
  const classes = useStyles();
  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className={classes.root}
    >
      <DialogTitle id="simple-dialog-title" className={classes.title}>
        <a href="mailto:bilal@mbilal.dev" className={classes.email}>
          bilal@mbilal.dev
        </a>
      </DialogTitle>
    </Dialog>
  );
}
