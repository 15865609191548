import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ContactMe from "./ContactMe";
import portfolio1 from "./images/port1.png";
import portfolio3 from "./images/port2.png";
import portfolio2 from "./images/port3.png";
import SiteCard from "./SiteCard";

const sites = [
  {
    link: "https://mbcod3.github.io/rest-countries-api-with-theme-switcher/",
    img: portfolio1,
    title: 'REST Countries API',
    details: 'React website with theme toggling for displaying list of countries & their information gotten from a rest api.'
  },
  {
    link: "https://mbcod3.github.io/url-shortener/",
    img: portfolio2,
    title: 'URL Shortener App',
    details: 'React url shortener app generate short links from rel.ink api and saves it for later in localStorage.'
  },
  {
    link: "https://mbcod3.github.io/rock-paper-scissors-game/",
    img: portfolio3,
    title: 'Rock, Paper, Scissors',
    details: 'React rock, paper, scissors game with spock and lizard version as well. Scoreboard saves each round score.'
  },
];

const useStyles = makeStyles({
  nav: {
    display: "flex",
    padding: "32px 0",
    marginBottom: "30px",
    justifyContent: "space-between",
    alignItems: "center",
    "& h2": {
      fontFamily: "Raleway, sans-serif",
      margin: 0,
    },
  },
  headline: {
    textAlign: "center",
    "& h1": {
      marginBottom: "10px",
    },
    "& h5": {
      marginTop: "10px",
      fontWeight: 400,
    },
  },
  portfolio: {
    marginTop: "60px",
    paddingBottom: '30px'
  },
  work: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  '@media (max-width: 1199px)': {
    work: {
      justifyContent: 'space-around',
      '& > div:nth-child(3)': {
        marginTop: '30px'
      }
    }
  },
  '@media (max-width: 991px)': {
    work: {
      '& > div:nth-child(2)': {
        marginTop: '30px'
      }
    },
    portfolioHeading: {
      textAlign: 'center'
    },
  },
});

export default function Hero() {
  const classes = useStyles();

  return (
    <div className="container">
      <nav className={classes.nav}>
        <h2>Bilal</h2>
        <ContactMe />
      </nav>

      <div className={classes.headline}>
        <h1>I Build Amazing Experiences</h1>
        <h5>React Web Developer</h5>
      </div>

      <div className={classes.portfolio}>
        <h4 className={classes.portfolioHeading}>My Work</h4>
        <div className={classes.work}> 
          {sites.map((site,i) => <SiteCard site={site} key={i} />)}
        </div>
      </div>
    </div>
  );
}
