import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import EmailDialog from './EmailDialog';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    textDecoration: 'none',
    color: '#7bdeea',
    borderBottom: '2px solid #7bdeea',
    padding: '5px 10px',
    borderRadius: '2px',
    cursor: 'pointer',
    '&:hover': {
      animation: '$background .1s forwards',
      animationDelay: '.2s'
    },
    '&:before': {
      content: "''",
      position: 'absolute',
      top: '-2px',
      left: '-2px',
      width: '0',
      height: '0',
      background: 'transparent',
      border: '2px solid transparent',
      borderRadius: '2px',
  },
    '&:hover:before': {
      animation: '$animate .2s linear forwards',
      animationDelay: '.1s'
    },
    
    '&:after': {
      content: "''",
      position: 'absolute',
      bottom: '-2px',
      left: '-2px',
      width: '0',
      height: '0',
      background: 'transparent',
      border: '2px solid transparent',
      borderRadius: '2px',
    },
    '&:hover:after': {
      animation: '$animate2 .1s linear forwards',
    }
  },
  '@keyframes animate2': {
    '0%': {
      width: '0',
      height: '0',
      borderTopColor: 'transparent',
      borderBottomColor: 'transparent',
      borderLeftColor: '#7bdeea',
      borderRightColor: 'transparent',
    },
    '100%': {
      width: '0',
      height: '100%',
      borderTopColor: 'transparent',
      borderBottomColor: '#7bdeea',
      borderLeftColor: '#7bdeea',
      borderRightColor: 'transparent',
    }
  },
  '@keyframes animate': {
    '0%': {
      width: '0',
      height: '0',
      borderTopColor: '#7bdeea',
      borderBottomColor: 'transparent',
      borderLeftColor: 'transparent',
      borderRightColor: 'transparent',
    },
    '50%': {
      width: '100%',
      height: '0',
      borderTopColor: '#7bdeea',
      borderBottomColor: 'transparent',
      borderLeftColor: 'transparent',
      borderRightColor: '#7bdeea',
    },
    '99%': {
      width: '100%',
      height: '100%',
      borderTopColor: '#7bdeea',
      borderBottomColor: 'transparent',
      borderLeftColor: 'transparent',
      borderRightColor: '#7bdeea',
    },
    '100%': {
      borderBottomColor: '#7bdeea',
      width: '100%',
      height: '100%',
      borderTopColor: '#7bdeea',
      borderLeftColor: 'transparent',
      borderRightColor: '#7bdeea',
    }
  },
  '@keyframes background': {
    '0%': {
      backgroundColor: 'transparent'
    },
    '70%': {
      backgroundColor: 'transparent'
    },
    '100%': {
      backgroundColor: 'rgba(176,224,230, 0.2)'
    }
  }
})

export default function ContactMe() {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false);
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <p className={classes.root} onClick={handleClickOpen}>Contact Me</p>
      <EmailDialog open={open} onClose={handleClose} />
    </React.Fragment>
  )
}
