import React from 'react';
import Hero from './Hero';
import { makeStyles } from '@material-ui/core/styles';
import heroImg from './images/hero.jpg'
import heroMob from './images/hero575.jpg';
import heroTab from './images/hero960.jpg';
import './fluidTypo';

const useStyles = makeStyles({
  '@global': {
    'html': {
      boxSizing: 'border-box',
    },
    body: {
      color: 'white',
      boxSizing: 'border-box',
      fontFamily: 'Poppins', 
    },
    '.container': {
      maxWidth: '100%',
      paddingRight: '15px',
      paddingLeft: '15px',
      marginRight: 'auto',
      marginLeft: 'auto',
      '@media(min-width: 576px)': {
        maxWidth: '540px',
      },
      '@media (min-width: 768px)': {
        maxWidth: '720px',
      },
      '@media (min-width: 992px)': {
        maxWidth: '960px',
      },
      '@media (min-width: 1200px)': {
        maxWidth: '1140px',
      },
      '@media (min-width: 1440px)': {
        maxWidth: '1340px',
      }
    },
    'img-fluid': {
      maxWidth: '100%',
      height: 'auto'
    }
  },
  app: {
    backgroundImage: 
    `linear-gradient(
      rgba(0, 0, 0, 0.75), 
      rgba(0, 0, 0, 0.75)
    ), url(${heroImg})`,
    backgroundSize: 'cover',
    minHeight: '100vh',
    '@media (max-width: 1200px)': {
      backgroundImage: 
      `linear-gradient(
        rgba(0, 0, 0, 0.75), 
        rgba(0, 0, 0, 0.75)
      ), url(${heroTab})`,
    },
    '@media (max-width: 576px)': {
      backgroundImage: 
      `linear-gradient(
        rgba(0, 0, 0, 0.75), 
        rgba(0, 0, 0, 0.75)
      ), url(${heroMob})`,
    }
  }
})

function App() {

  const classes = useStyles()
  return (
    <div className={classes.app}>
      <Hero />
    </div>
  );
}

export default App;
