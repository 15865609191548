import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles({
  root: {
    maxWidth: '100%',
    width: 350,
    backgroundColor: '#1a1919b3',
    textAlign: 'center',
    boxShadow: '0px 5px 7px -2px rgba(0,0,0,0.76)',
    color: 'white',
    '& h2': {
      fontFamily: 'Poppins'
    },
    '& p': {
      color: '#b7b7b7',
      fontFamily: 'Poppins',
    },
    '&:hover': {
      boxShadow: '0px 6px 7px 0px rgba(0,0,0,0.9)',
    }
  },
  visit: {
    margin: '0 auto',
    fontFamily: 'Poppins',
    color: '#7bdeea',
    '&:hover': {
      backgroundColor: '#ffffff1a'
    }
  },
  '@media (min-width:1440px)': {
    root: {
      width: 410
    }
  },
  '@media (max-width:1199px)': {
    root: {
      width: 410
    }
  }
});


export default function SiteCard({site}) {
  const classes = useStyles()
  const {link, img, title, details} = site

  const open = (e) => {
    e.preventDefault();
    setTimeout(() => window.open(link, '_blank'), 400)
  }
  return (
    <Card className={classes.root} onClick={open}>
      <CardActionArea className={classes.actionArea}>
        <CardMedia
          className={classes.media}
          image={img}
          title={title}
          component="img"
        />
        <CardContent>
          <Typography gutterBottom variant="h6" component="h2">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {details}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" className={classes.visit} component="a">
            Visit Website
          </Button>
        </CardActions>
      </CardActionArea>
    </Card>
  )
}
